import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import GlassmaxLogo from '../assets/img/glassmax_logo.png'
import ContactIcon from '../assets/icon/contact.png'
import OpenIcon from '../assets/icon/open.png'
import {Link, Stack} from "@mui/material";
import {NavLink} from "react-router-dom";

const pages = ['Products', 'Pricing', 'Blog'];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="fixed">
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Box sx={{ display: 'flex', mr: 1, marginRight: '40px' }}>
                        <NavLink to="/">
                            <img src={GlassmaxLogo} alt="Glassmax logo" width={200}/>
                        </NavLink>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, marginLeft: 'auto', justifyContent: 'right' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            //PaperProps={{sx: {width: '800px'}}}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                '& .MuiPaper-root': {
                                    width: '100%',
                                    backgroundColor: 'primary.main'

                            }
                            }}
                        >
                            <MenuItem onClick={handleCloseNavMenu}>
                                <Stack spacing={3} direction="row" alignItems="center" justifyContent="flex-end">
                                    <Stack spacing={2} direction="row">
                                        <Box >
                                            <img src={ContactIcon} width={20} height={20} alt="contact icon"/>
                                        </Box>
                                        <Stack direction="column">
                                            <Typography variant="body2" fontSize={10} color='#ffffff'>+36 30 581 4678</Typography>
                                            <Typography variant="body2" fontSize={10} color='#ffffff'>ertekesites@glassmax.hu</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack spacing={2} direction="row">
                                        <img src={OpenIcon} width={20} height={20} alt="opening hours icon"/>
                                        <Stack direction="column">
                                            <Typography variant="body2" fontSize={10} color='#ffffff'>8:00 - 16:30</Typography>
                                            <Typography variant="body2" fontSize={10} color='#ffffff'>Hétfőtől Péntekig</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <NavLink to="/#rolunk" className="nav-item">
                                    <Box justifyContent="center">
                                        <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>RÓLUNK</Typography>
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <NavLink to="/#casco" className="nav-item">
                                    <Box justifyContent="center">
                                        <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>CASCO</Typography>
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <NavLink to="/#garancia" className="nav-item">
                                    <Box justifyContent="center">
                                        <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>GARANCIA</Typography>
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <NavLink to="/keszletunk.html" className="nav-item">
                                    <Box justifyContent="center">
                                        <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>KÉSZLETÜNK</Typography>
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <NavLink to="/#flotta" className="nav-item">
                                    <Box justifyContent="center">
                                        <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>FLOTTA</Typography>
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <NavLink to="/tudta.html" className="nav-item">
                                    <Box justifyContent="center">
                                        <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>TUDTA?</Typography>
                                    </Box>
                                </NavLink>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <NavLink to="/gyik.html" className="nav-item">
                                    <Box justifyContent="center">
                                        <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>GYIK</Typography>
                                    </Box>
                                </NavLink>
                            </MenuItem>

                        </Menu>
                    </Box>
                    <Stack spacing={4} direction="row" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <NavLink to="/#rolunk" className="nav-item" style={({ isActive, isPending, isTransitioning }) => {
                            return {
                                fontWeight: isActive ? "bold" : "",
                                color: isPending ? "red" : "black",
                                viewTransitionName: isTransitioning ? "slide" : "",
                            };
                        }}>
                            <Box justifyContent="center">
                                <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>RÓLUNK</Typography>
                            </Box>
                        </NavLink>
                        <NavLink to="/#casco" className="nav-item">
                            <Box justifyContent="center">
                                <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>CASCO</Typography>
                            </Box>
                        </NavLink>
                        <NavLink to="/#garancia" className="nav-item">
                            <Box justifyContent="center">
                                <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>GARANCIA</Typography>
                            </Box>

                        </NavLink>
                        <NavLink to="/keszletunk.html" className="nav-item">
                            <Box justifyContent="center">
                                <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>KÉSZLETÜNK</Typography>
                            </Box>

                        </NavLink>
                        <NavLink to="/#flotta" className="nav-item">
                            <Box justifyContent="center">
                                <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>FLOTTA</Typography>
                            </Box>

                        </NavLink>
                        <NavLink to="/tudta.html" className="nav-item">
                            <Box justifyContent="center">
                                <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>TUDTA?</Typography>
                            </Box>

                        </NavLink>
                        <NavLink to="/gyik.html" className="nav-item">
                            <Box justifyContent="center">
                                <Typography className="nav-link" variant="body1" fontWeight="bold" color='#ffffff'>GYIK</Typography>
                            </Box>

                        </NavLink>
                    </Stack>

                    <Stack spacing={3} direction="row" alignItems="center" justifyContent="flex-end" sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <Stack spacing={2} direction="row">
                            <Box >
                                <img src={ContactIcon} width={37} height={34} alt="contact icon"/>
                            </Box>
                            <Stack direction="column">
                                <Link href="tel:+36305814678" underline="none" color='#ffffff'><Typography variant="body1">+36 30 581 4678</Typography></Link>
                                <Link href="mailto:ertekesites@glassmax.hu" underline="none" color='#ffffff'><Typography variant="body1">ertekesites@glassmax.hu</Typography></Link>
                            </Stack>
                        </Stack>
                        <Stack spacing={2} direction="row">
                            <img src={OpenIcon} width={40} height={40} alt="opening hours icon"/>
                            <Stack direction="column">
                                <Typography variant="body1">8:00 - 16:30</Typography>
                                <Typography variant="body1">Hétfőtől Péntekig</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
