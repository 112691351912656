import {Box, Container, Link, Stack, Typography} from "@mui/material";
import Image from "../assets/img/glassmax_karrier_2_ff.jpg";
import React from "react";
import {Helmet} from "react-helmet";

function Carrier () {
    return(
        <>
            <Helmet>
                <title>Szélvédő Javítás és Csere - Akár még ma - Glassmax</title>
                <meta name="description" content="Térjen vissza mielőbb az utakra. Kőfelverődés javítás német technológiával. Teljeskörű CASCO ügyintézés, 1 év garancia. Több mint 20 év tapasztalat." />
            </Helmet>
            <Container className="content" sx={{paddingX: {xs: 0 }}}>
                <Stack spacing={5} flexDirection="column" justifyContent="flex-start" justifyItems="flex-start">
                    <Box textAlign="center">
                        <Box component="img" src={Image} alt="Stock picture" sx={{content: `url(${Image})`, width: { xs: '100%', md: 'auto'}, height: 'auto' }} />
                    </Box>
                    <Stack spacing={3} flexDirection="column" justifyContent="flex-start" justifyItems="flex-start" textAlign="left">
                        <Box>
                            <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>KARRIER</Typography>
                            <Box className="sep"/>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            Tudsz és szeretsz igényesen dolgozni?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Szereted, ha megbecsülik a munkádat?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Várunk egy jó csapatban!
                        </Typography>
                        <Typography sx={{ typography: { sm: 'h4', xs: 'h5' } }} gutterBottom>
                            Jelentkezz hozzánk ÜGYFÉLSZOLGÁLATI MUNKATÁRS pozíciónkba!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            A munka jellege: heti 40 óra
                        </Typography>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Mik lesznek a feladataid?
                        </Typography>
                        <ul>
                            <li>kapcsolattartás az ügyfelekkel</li>
                            <li>időpontok kezelése</li>
                            <li>árajánlatok kiküldése</li>
                            <li>munkafelvétel</li>
                            <li>számlázás</li>
                            <li>a munkakörrel járó adminisztratív feladatok ellátása</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Mit várunk tőled?
                        </Typography>
                        <ul>
                            <li>jó kommunikációs és problémamegoldó készség</li>
                            <li>üzleti gondolkodásmód</li>
                            <li>ügyfélbarát hozzáállás</li>
                            <li>csapatban való együttműködés</li>
                            <li>számítógépes ismeretek (Microsoft Office)</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Előnyt jelent,
                        </Typography>
                        <ul>
                            <li>ha van autóipari tapasztalatod</li>
                            <li>angol nyelvtudás</li>
                        </ul>
                        <Typography variant="body1" fontWeight="bold" gutterBottom>
                            Amit kínálunk:
                        </Typography>

                    <ul>
                        <li>bejelentett jogviszony, versenyképes bérezés</li>
                        <li>szakszerű betanítási folyamat</li>
                        <li>modern, tiszta munkakörnyezet</li>
                        <li>ingyenes parkolás</li>
                        <li>sokéves stabil háttérrel rendelkező cég</li>
                        <li>fiatalos csapat</li>
                        <li>szabad esték és hétvégék</li>
                    </ul>

                    <Typography variant="body1" fontWeight="bold" gutterBottom>
                        Rólunk
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            A Glass Max Hungary Kft immáron több mint éve 20 éve meghatározó alakja a
                            magyar autó szélvédő piacnak. Saját szerelőműhellyel és szélvédő raktárral
                            rendelkezik, mely több mint 20.000 db terméknek biztosít helyet. Külföldi
                            beszállítókkal és gyárakkal való együttműködése, illetve ezek befektetései
                            határozottan versenyképessé teszik az itthoni piacon. Kiváló kapcsolat az itthoni
                            biztosító cégekkel és több neves autó szalonnal is.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Felkeltettük érdeklődésed?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Fényképes önéletrajzod az <Link href="mailto:adamdaadou@gmail.com">adamdaadou@gmail.com</Link> e-mail címre várjuk.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Örülünk, hogy megismerhetünk!
                        </Typography>
                   </Stack>
                </Stack>
            </Container>
        </>
    )
}

export default Carrier