import {Box, Container, Stack, Typography} from "@mui/material";
import LucasLogo from "../assets/icon/logo-lucas-mobile.png"
import ProGlassLogo from "../assets/icon/logo-pro-glass.png"
import AlpGiegLogo from "../assets/icon/logo-alp-gieg.png"
import GlavistaLogo from "../assets/icon/logo-glavista.png"
import MasterAutoGlassLogo from "../assets/icon/logo-master-auto-glass.png"
import AgeLogo from "../assets/icon/logo-age.png"
import React from "react";

function Partner() {
    return(
        <>
            <Container sx={{ textAlign: 'center', marginY: '20px' }}>
                <Typography variant="h4" gutterBottom>PARTNEREINK</Typography>
                <Box className="sep" sx={{marginX: 'auto'}}/>
                <Stack
                    direction={{xs: 'column', md: 'row'}}
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={2}
                    sx={{ direction: { xs: "column", md: "row" } }}
                >
                    <Box component="img" src={LucasLogo} alt="LucasLogo"/>
                    <Box component="img" src={ProGlassLogo} alt="ProGlassLogo"/>
                    <Box component="img" src={AlpGiegLogo} alt="AlpGiegLogo"/>
                    <Box component="img" src={GlavistaLogo} alt="GlavistaLogo"/>
                    <Box component="img" src={MasterAutoGlassLogo} alt="MasterAutoGlassLogo"/>
                    <Box component="img" src={AgeLogo} alt="AgeLogo"/>
                </Stack>
                <Typography variant="body1" fontWeight="bold">A Glassmax kizárolag válogatott autóüveg gyártók prémium szélvédőivel, szerszámaival és javítási
                    segédanyagaival dolgozik.</Typography>
            </Container>
        </>
    )
}

export default Partner